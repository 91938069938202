import React from "react";
import { Link } from "react-router-dom";
import PracticeLinesImage from "../../assets/images/practice_lines.png";

export default function ScheduleDemo() {
  return (
    <section
      className="section-pading schedule_demo_section section_padding"
      style={{
        backgroundImage: "url(" + PracticeLinesImage + ")",
      }}
    >
      <div className="container">
        <div className="align-items">
          <div className="col-sm-7">
            <h2>
              Discover the only complete practice growth platform in healthcare.
            </h2>
          </div>
          <div className="col-sm-5">
            <Link to="/schedule-demo">
              <div className="home-banner-bolton Schedule_buton_footer cursor-pointer">
                Schedule a demo&nbsp; &nbsp;{" "}
                <i className="fas fa-arrow-right" aria-hidden="true" />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
