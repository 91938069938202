import React from "react";
import CookieConsent from "react-cookie-consent";

export default function CookieSection() {
  return (
    <CookieConsent
      location="bottom"
      buttonText="I Agree"
      cookieName="myAwesomeCookieName2"
      style={{ background: "#2B373B" }}
      buttonStyle={{ color: "#4e503b", fontSize: "14px" }}
      expires={150}
      cookieValue={true}
      onAccept={(a) => {
        console.log(a);
      }}
      // onDecline={(d) => {
      //   console.log(d);
      // }}
      // declineButtonText="Decline"
      // enableDeclineButton
    >
      This website uses cookies to enhance the user experience.{" "}
      <span className="cookie-link">
        <a
          href="https://google.co.in/"
          className="cookie-link"
          target="_blank"
          rel="noreferrer"
        >
          Cookie Policy
        </a>
      </span>
    </CookieConsent>
  );
}
